<template>
  <div class="v-stack h-stretch gap-3" v-if="workday">
    <a class="header text-left">Day Overview</a>
    <div class="card light">
      <div class="content">
        <div class="v-stack gap-3">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Name:</label>
            <div class="text-left">
              {{ workday.user.firstName }} {{ workday.user.lastName }}
            </div>
          </div>

          <div class="pane-horizontal gap-3">
            <label class="text-right">Date:</label>
            <div class="text-left">
              {{ formatDate(workday.day, "DD.MM.YYYY") }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">From:</label>
            <div class="text-left">
              {{ formatDate(workday.start, "HH:mm") }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">To:</label>
            <div class="text-left">{{ formatDate(workday.end, "HH:mm") }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Hours:</label>
            <div class="text-left">{{ sumWorkhours() }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Money:</label>
            <div class="text-left">
              {{ format.currency(calculateMoney()) }} Kč
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Hour rate:</label>
            <div class="text-left">
              {{ format.currency(Math.round(workday.workhours[0].pay.amount)) }}
              Kč
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right my">Signed:</label>
            <i
              v-if="workday.state == 'canceled'"
              class="text-left fas fa-times fa-2x text-error"
            ></i>
            <i
              v-if="workday.state == 'signed'"
              class="text-left fas fa-check fa-lg text-success"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <a class="header text-left">Workhours</a>
    <div class="table">
      <div class="table-header workhour-row">
        <div>Project</div>
        <div>Hours</div>
        <div>Activity</div>
        <div>Comment</div>
      </div>
      <div
        v-for="workhour in workday.workhours"
        :key="workhour._id"
        class="table-row workhour-row"
      >
        <div>
          {{ workhour.project.client.name }} / {{ workhour.project.title }} /
          {{ workhour.project.subtitle }}
        </div>
        <div>{{ workhour.hours }}</div>
        <div>{{ workhour.activity }}</div>
        <div>{{ workhour.comment }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
import { mapActions } from "vuex";
import utils from "@/utils.js";

export default {
  props: ["id"],
  data() {
    return {
      workday: null,
      format: utils.format,
    };
  },
  methods: {
    ...mapActions(["getWorkday"]),
    sumWorkhours() {
      let result = 0;
      for (const workhour of this.workday.workhours) {
        result += workhour.hours;
      }
      return result;
    },
    calculateMoney() {
      let result = 0;
      for (const workhour of this.workday.workhours) {
        result += workhour.hours * workhour.pay.amount;
      }
      return Math.round(result);
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
  mounted() {
    this.getWorkday(this.id)
      .then((workday) => {
        this.workday = workday;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.workhour-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}

.my {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
